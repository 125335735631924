import request from 'arcdynamic-request/request';

function createForm(user) {
	const form = document.createElement('form');
	form.id = 'custom_form';
	form.innerHTML = `
<div id='custom_body'>
	<style>
		#custom_form {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: auto;
			padding: 20px;
			background: rgba(0,0,0,0.5);
			z-index: 999;
		}

		#custom_body {
			margin: 0 auto;
			padding: 40px;
			background: #fff;
			max-width: 500px;
			border-radius: 10px;
		}
		#custom_form label {
			display: block;
		}
		#custom_form input[type='text'],
		#custom_form input[type='number'],
		#custom_form input[type='email'],
		#custom_form input[type='textarea'] {
			width: 100%;
		}
		.custom_sec {
			display: block;
			margin-bottom: 15px;
		}
	</style>

	<h2>Supplemental Information</h2>
	<div class='custom_sec'>
		<label>
			<div>Customer name:</div>
			<input required type='text' name='customer-name' value='${user.person ? user.person.firstName+' '+user.person.lastName : ''}'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Patient name:</div>
			<input required type='text' name='patient-name'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Patient email:</div>
			<input required type='email' name='patient-email'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Patient phone number:</div>
			<input required type='text' name='patient-phone'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Patient shipping address:</div>
			<textarea required name='patient-address'></textarea>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Hospital name:</div>
			<input required type='text' name='hospital-name'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Approximate length of stay:</div>
			<input required type='text' name='length-of-stay'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>For how many people do you need Kosher Kare food?</div>
			<input required type='number' name='number-of-patients'/>
		</label>
	</div>
	<div class='custom_sec'>
		<label>
			<div>Are there any special dietary restrictions or requirements?</div>
			<textarea name='dietary-restrictions'></textarea>
		</label>
	</div>
	<div class='custom_sec'>
		<div>Which of the following do you have access to?</div>
		<label><input type='checkbox' value='Hot Plate' name='appliances'/> Hot Plate</label>
		<label><input type='checkbox' value='Freezer' name='appliances'/> Freezer</label>
		<label><input type='checkbox' value='Microwave' name='appliances'/> Microwave</label>
		<label><input type='checkbox' value='Refrigerator' name='appliances'/> Refrigerator</label>
	</div>
	<button type='submit'>Submit</button>
</div>
	`.trim();

	return form;
}

let userInfo = null;
let form = null;

document.body.addEventListener('onStateChange', (e)=> {
	const {state, prevState} = e.detail;

	if (userInfo && state.purchase) {
		userInfo.PURCHASE = state.purchase.code;

		request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['KKAREPORT', {
				value: [userInfo],
			}],
		}, {}, (res)=> {
			// response ignored, fire and forget.
		});
		userInfo = null;
		form = null;
	}

	if (!userInfo && !form && state.location.pathname === '/cart/checkout' && state.user) {
		form = createForm(state.user);

		form.addEventListener('submit', e => {
			e.preventDefault();
			userInfo = {
				PURCHASE: null,
				CUSTOMER_EMAIL: state.user.email ? state.user.email : '',
				CUSTOMER_NAME: form.elements['customer-name'].value,
				PATIENT_NAME: form.elements['patient-name'].value,
				PATIENT_EMAIL: form.elements['patient-email'].value,
				PATIENT_PHONE: form.elements['patient-phone'].value,
				PATIENT_ADDRESS: form.elements['patient-address'].value,
				HOSPITAL: form.elements['hospital-name'].value,
				LENGTH_OF_STAY: form.elements['length-of-stay'].value,
				PATIENTS: form.elements['number-of-patients'].value,
				DIETARY_RESTRICTIONS: form.elements['dietary-restrictions'].value,
				APPLIANCES: [...form.elements['appliances']].filter(el => el.checked).map(el => el.value).join(', ')
			};

			document.body.removeChild(form);
			document.body.style.overflow = '';
		});

		document.body.appendChild(form);
		document.body.style.overflow = 'hidden';
	}
});